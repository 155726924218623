// 
//  User profile details.scss
//

// User profile details

.user-profile-sidebar {
    // height: calc(100vh - 180px);
    background-color: $card-bg;
    display: none;
    min-width: 50%;
    max-width: 50%;

    @media (min-width: 992px) {
        border-left: 4px solid $border-color;
    }

    @media (max-width: 1023.98px) {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 99;
        border-left:1px solid $border-color;
    }

    @media (max-width: 767.99px) {
        min-width: 100%;
        height: 100vh;
    }
}

.user-profile-desc {
    height: calc(100vh - 300px);

    @media (max-width: 991.98px) {
        height: calc(100vh - 324px);
    }
}


