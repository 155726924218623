/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 2, 2020 */
/*
@font-face {
  font-family: 'Kanit';
  src: url('kanit-regular.woff2') format('woff2'), url('kanit-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'KanitMedium';
  src: url('kanit-medium.woff2') format('woff2'), url('kanit-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'KanitSemiBold';
  src: url('kanit-medium.woff2') format('woff2'),
       url('kanit-medium.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
*/
/* @font-face {
    font-family: 'kanititalic';
    src: url('kanit-italic.woff2') format('woff2'),
         url('kanit-italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
} */

/* @font-face {
    font-family: 'kanitblack';
    src: url('kanit-black.woff2') format('woff2'),
         url('kanit-black.woff') format('woff');
    font-weight: normal;
    font-style: normal;
} */




@font-face {
  font-family: 'Kanit';
  src: url('Sarabun-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KanitSemiBold';
  src: url('Sarabun-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'KanitMedium';
  src: url('Sarabun-Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}