//
// user chat.scss
//

// user chat

.user-chat {
  background-color: $card-bg;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  transition: all 0.4s;
  @media (max-width: 991.98px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &.agent {
      visibility: hidden;
      transform: translateX(100%);
      z-index: 99;
    }

    &.user-chat-show {
      visibility: visible;
      transform: translateX(0);
    }
  }
}

.user-chat-nav {
  .nav-btn {
    height: 40px;
    width: 40px;
    line-height: 40px;
    box-shadow: none;
    padding: 0;
    font-size: 20px;
    color: $gray-600;
  }
  @media (max-width: 575.98px) {
    display: flex;
    justify-content: flex-end;
  }

  &.close-icon {
    position: absolute;
    right: 10px;
  }
}

.chat-conversation {
  li {
    clear: both;
  }

  .chat-avatar {
    margin: 0 3px 0 0 /*rtl:0 0 0 16px*/;

    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }

  .chat-day-title {
    position: relative;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 12px;

    .title {
      background-color: $border-color;
      position: relative;
      font-size: 13px;
      z-index: 1;
      padding: 6px 12px;
      border-radius: 5px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $border-color;
      top: 10px;
    }

    .badge {
      font-size: 12px;
    }
  }

  .conversation-list {
    margin-bottom: 10px;
    display: inline-flex;
    position: relative;
    align-items: flex-end;
    
    .ctext-wrap {
      display: flex;
      margin-bottom: 10px;
    }

    .ctext-wrap-content {
      padding: 12px 20px;
      background-color: $chat-primary-bg;
      border-radius: 8px 8px 8px 0;
      color: $white;
      position: relative;
      max-width: 260px;

      p,div {
        word-break: break-word;
        font-size: 20px;
      }
      a {
        color: #fff;
        text-decoration: underline !important;
      }

      &:before {
        content: '';
        position: absolute;
        border: 5px solid transparent;
        border-left-color: $chat-primary-bg;
        border-top-color: $chat-primary-bg;
        left: 0 /*rtl:auto*/;
        right: auto /*rtl:0*/;
        /*bottom: -10px;*/
      }
    }

    .conversation-name {
      font-weight: $font-weight-medium;
      font-size: 14px;
    }

    .dropdown {
      .dropdown-toggle {
        font-size: 18px;
        padding: 4px;
        color: $gray-600;

        @media (max-width: 575.98px) {
          display: none;
        }
      }
    }

    .chat-time {
      font-size: 12px;
      margin-top: 4px;
      text-align: right;
      color: rgba($white, 0.5);
    }

    .message-img {
      border-radius: 0.2rem;
      position: relative;

      .message-img-list {
        position: relative;
      }

      img {
        //max-width: 150px;/* revise download btn */
        max-width: 260px; /* revise download btn */
      }

      .message-img-link {
        position: absolute;
        right: 10px /*rtl:auto*/;
        left: auto /*rtl:0*/;
        bottom: 10px;

        li {
          > a {
            font-size: 18px;
            color: $white;
            display: inline-block;
            line-height: 30px;
            width: 30px;
            height: 30px;
            text-align: center;
          }
        }
      }
    }
  }

  .right {
    .chat-avatar {
      order: 3;
      margin-right: 0px;
      margin-left: 16px;
    }

    .chat-time {
      text-align: left;
      color: $gray-600;
    }

    .conversation-list {
      float: right;
      text-align: right;

      .ctext-wrap {
        justify-content: flex-end;

        .ctext-wrap-content {
          order: 2;
          background-color: $chat-secondary-bg;
          color: $dark;
          text-align: left;
          border-radius: 8px 8px 0px 8px;
          max-width: 300px;

          p,div {
            word-break: break-word;
            font-size: 20px;
          }

          a {
            color: #343a40;
          }

          &:before {
            border: 5px solid transparent;
            border-top-color: $chat-secondary-bg;
            border-right-color: $chat-secondary-bg;
            //left: auto;
            //right: 0px;
          }
        }
      }

      .dropdown {
        order: 1;
      }
    }

    .dot {
      background-color: $dark;
    }
  }
}

// chat ของหน้า  agent  
.agent{
  .chat-conversation { 
    height: calc(var(--height-100) - 365px);
    @media (max-width: 991.98px) { 
      height: calc(var(--height-100) - 156px);
    }
  }
  .chat-input-parent{
    position: relative;
    // ต้องตั้งไว้เพื่อแก้ d-lg-flex ไม่งั้นโดน flex บังคับให้สั้น แต่จะยาวขึ้นเพราะเมื่อเปิด .profile-info จะมี height มาค้ำ
    min-height: calc(var(--height-100) - 178px);
    @media (max-width: 991.98px) { 
      min-height: calc(var(--height-100) - 0px);
    }
  }
  .chat-input-message-multiline {
    position:absolute;
    bottom:0px;
    width: 100%;
    background-color: #fff;
    @media (max-width: 991.98px) { 
      bottom:0px;
    }
    .textarea-auto-expand{ 
      transition: all 0.5s ease;
      resize: none;
    }
  }
}

// chat ของหน้า  consumer 
.chat-consumer, .chat-consumer-opd {
  .chat-conversation { 
    height: calc(var(--height-100) - 350px );
    @media (max-width: 991.98px) { 
      height: calc(var(--height-100) - 155px);
    }
  }
  .chat-input-message-multiline {
    position:absolute;
    width: 100%!important;
    bottom:-90px;
    background-color:#ffffff;
    @media (max-width: 991.98px) { 
      bottom:0px;
    }
    .textarea-auto-expand{ 
      transition: all 0.5s ease;
      resize: none;
    }
  }
}

.user-profile-show{
  line-height: 2rem;
}

/*
  Look like line
*/
.chat-conversation{
  background: #99a8d1;
  .conversation-list {
    > div:first-child{display:flex;align-self: start;}
    .ctext-wrap-content {
      background-color: #ffffff;
      border-radius: 20px 20px 20px 20px;
      //padding:8px 20px 8px 20px; /* revise download btn */
      padding:0px!important; /* revise download btn */
      color: #000000;
      a {color: #343a40;}
      position:relative;
      p.chat-time{
        color: #6a747f;font-size:12px;
        width:50px;
        white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
        position:absolute;right:-53px;bottom:0px;
      }
      &:before {
        border: 8px solid transparent;
        border-top-color: #ffffff;
        top:2px;left:-1px;
      }

      /* revise download btn */
      div.mb-0{
        padding:8px 20px 8px 20px;
      }
      .list-inline-item.message-img-list{
        padding:0px;
        background: #99a8d1;
        .card{
          background-color:transparent;
          /* revise download btn */
          padding: 0px !important;
          div.align-items-center{
            div.flex-1{
              div.popup-img{
                margin: 0px !important;
                margin-right: -3px !important;
              }
            }
            div.ms-4{
              display: none;
            }
          }
          
        }
      }
      /* file */
      div.p-2.mb-2.card{
        margin-bottom: 0px !important;
        background-color: transparent;
      }
      /* end revise download btn */

    }
    
    .user-chat-content{
      display:flex;flex-direction:column;
      .ctext-wrap{order:2;}
      .conversation-name {order:1;}
    }
  }
}

/* right */
.chat-conversation{
  .right {
    .conversation-list {
      .ctext-wrap {
        .ctext-wrap-content {
          background-color: #c3f69d!important;
          border-radius: 20px 20px 20px 20px;
          padding:8px 20px 8px 20px;
          color: #000000;
          a {color: #343a40;}
          position:relative;
          p.chat-time{
            color: #6a747f;font-size:12px;
            width:50px;
            white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
            position:absolute;left:-53px;bottom:0px;
          }
          &:before {
            border: 8px solid transparent;
            border-top-color: #c3f69d;
            top:2px;left:10px;right:-2px;z-index: -1;
          }
        }
      }
    }
  }
}

// อื่นๆ
.chat-input-links {
  .list-inline-item:not(:last-child) {
    margin: 0;
  }
}

.animate-typing {
  .dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-right: -1px;
    /*background: $white;*/
    background: #343a40;
    animation: wave 1.3s linear infinite;
    opacity: 0.6;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-5px);
  }
}


.agent-qr-text{
  font-size: 14px;
  cursor: pointer;
}